@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,300;0,400;0,600;0,800;1,200;1,400;1,1000&display=swap');

* {
  margin: 0;
  color: #091E42;
  font-family: 'Nunito', sans-serif;
}

body {
  background-color: #FFFFFF;
  text-align: center;
}
@media screen and (max-width: 425px){
  .about-me p{
    text-align: justify;
  }
  .modal-text p{
    text-align: left;
  }
  .contact-container p{
    font-size: 16px !important;
  }
  .contact-container {
    grid-template-columns: auto !important;
    margin: 3em 0px;
    row-gap: 1.5em;
  }
  .section-container{
    margin-top: 3.5em !important;
  }
  .project-section{
    padding-top: 1.5em !important;
  }
}

@media screen and (max-width: 820px){
  p{
    font-size: 15px !important;
  }
  h1{
    font-size: 30px !important;
  }
  h2{
    font-size: 26px !important;
  }
  .scroll-hint-arrow {
    border-width: 0 0 2px 2px !important;
  }
  .scroll-hint-text {
    font-size: 14px !important;
  }
  .background-container h3{
    font-size: 16px;
  }
  .background h4{
    font-size: 14px !important;
  }
  .skill-details h4{
    font-size: 14px !important;
  }
  .vertical-timeline-element-content h3{
    font-size: 20px;
  }
  .vertical-timeline-element-content h4{
    font-size: 16px;
  }
  .vertical-timeline-element-discription{
    font-size: 14px;
  }
  .skill-block{
    margin: 10px !important;
  }
  .skill-block img{
    width: 70px;
    height: 70px;
  }
  .skill-block h4{
    font-size: 18px;
  }
  .project-card{
    margin: 0.75em !important;
  }
  .contact-text{
    font-size: 20px !important;
  }
  .modal-lists{
    font-size: 15px !important;
  }
}
@media screen and (max-width: 650px){
  .skills-grid-container{
    margin-top: 1rem !important;
    grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
  }
}
@media screen and (max-width: 1050px) {
  .projects-container {
    overflow: auto;
    justify-content: start !important;
  }
}
@media screen and (max-width: 1250px) {
  .project-name{
    font-size: 18px !important;
  }
  .project-discription {
    font-size: 14px !important;
  }
  .project-tech-stack {
    font-size: 12px !important;
  }
  .learn-more-text {
    font-size: 12px !important;
  }
}

p{
  font-size: 20px;
}

h2{
  font-size: 40px;
}

.navbar {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  margin: 0 auto;
  background-color: #FFFFFF;
  flex-direction: row-reverse;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px, rgba(17, 17, 26, 0.1) 0px 0px 8px;
}

.categories{
  display: flex;
}

.menu-item{
  margin: 1rem 1.5rem;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
  position: relative;
}

.underline {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 4px;
  border-radius: 15px; 
  background:  #edcf2e;
  opacity: 0.85;
}

.nav-menu-icon {
  display: block;
  width: 2em;
  height: 2em;
  margin: 0.75rem 1.5rem;
  position: relative;
  z-index: 1001;
}

.nav-menu-backdrop {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 105%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
}

.white-text{
  color: #FFFFFF;
  fill:#FFFFFF;
}

.home {
  height: calc(100vh - 50px);
  width: 70%;
  margin: auto;
  position: relative;
}

.intro-container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.scroll-hint-component {
  width: max-content;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

.scroll-hint-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  margin-bottom: 10px;
  animation-name: scrollHintAnimation;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

.scroll-hint-arrow {
  border: solid #091E42;
  border-width: 0 0 3px 3px;
  display: inline-block;
  padding: 5px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); 
  animation: arrowAnimation 1.5s infinite;
}

.scroll-hint-text {
  margin-top: 20px;
  color: #091E42;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
}

@keyframes arrowAnimation {
  0% {
    ransform: rotate(-45deg) translate(0, 0);
  }
  50% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  100% {
    transform: rotate(-45deg) translate(0, 0);
  }
}

@keyframes scrollHintAnimation {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.section {
  min-height: 100vh;
  width: 90%;
  margin: auto;
  padding-top: 2rem;
  display: flex;
}

.section-container {
  width:100%;
  margin: auto;
}

.about-me {
  position: relative;
  display: grid;
  width: 80%;
  margin: 5% auto;
  background-color: #FFFFFF;
  z-index: 99;
}

.about-me p{
  font-size: 20px;
  margin-top: 1em;
}

.background-container {
  width: fit-content;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 10rem;
  font-size: 22px;
  background: #FFFFFF;
  position: relative;
  z-index: 99;
  padding: 3%;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.background {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 30vh;
  width: 50vw;
  min-width: 300px;
  margin-top: 5%;
  margin-bottom: 3%;
  font-size: 20px;
}

.working-experience-container {
  margin-top: 4em;
  margin-bottom: 5em;
}

.vertical-timeline-element-title {
  font-size: 24px;
}

.vertical-timeline-element-subtitle {
  font-size: large;
}

.vertical-timeline-element-discription {
  margin: 1em 0;
  text-align: left;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25),
    0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 1em;
}

.vertical-timeline-element-date {
  background-color: transparent;
  text-align: center !important;
}

.skill-details {
  display: grid;
  grid-template-columns: 1fr 2fr;
  height: 30vh;
  min-width: 300px;
  margin-top: 5%;
  margin-bottom: 3%;
  font-size: 20px;
}

.skills-grid-container {
  margin-top: 1vh;
  min-width: 300px;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  font-size: 24px;
}

.skill-block {
  margin: 20px 20px;
  padding: 20px 10px;
  position: relative;
  z-index: 99;
}

.project-section {
  min-height: 100vh;
  margin: auto;
  display: flex;
}

.projects-container {
  display: flex;
  height: 70vh;
  align-items: center;
  justify-content: center;
}

.projects-container::-webkit-scrollbar{
  display: none;
}

.project-card {
  display: grid;
  grid-template-rows: 2fr 1fr;
  width: 28vw;
  min-width: 320px;
  height: 28vw;
  min-height: 320px;
  margin: 1em;
  background: rgb(233,242,255);
  border-radius: 5%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
  z-index: 99;
}

.project-card-image {
  max-width: 100%;
  min-height: 180px;
  background-color: #FFFFFF;
}

.images { 
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.project-card-text {
  padding: 1em;
  margin-top: 0.25em;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.project-name {
  font-size: 24px;
}

.project-discription {
  font-weight: 500;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font-size: 18px;
}

.project-tech-stack {
  font-weight: 300;
  font-style: italic;
  font-size: 16px;
}

.learn-more {
  margin-top: 0.5em;
  display: block;
  width: 100%;
  text-align: right;
}

.learn-more-text {
  font-size: 14px;
  font-style: italic;
  font-weight: lighter;
}

.learn-more-arrow {
  border: solid #091E42;
  border-width: 0 1px 1px 0px;
  display: inline-block;
  padding: 3px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg); 
}
@media screen and (min-width: 850px){
  .project-card:hover {
    cursor: pointer;
    background: rgb(223, 237, 255);
  }
  .project-card:hover .learn-more-text{
    font-weight: 400;
    text-decoration: underline;
    animation: learnMoreAnimation 1.5s infinite;
  }
  .project-card:hover .learn-more-arrow{
    animation: learnMoreAnimation 1.5s infinite;
  }
}

@keyframes learnMoreAnimation {
  0% {
    opacity: 0.2;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.2;
  }
}

.modal{
  display: block;
  overflow-y: auto;
  height: 100%;
}

.modal-topbar{
  width: 90%;
  display: flex;
  margin: 1em 0.5em;
}

.modal-media{
  height: 40vh;
  display: flex;
  gap: 1%;
  margin-top: 3%;
  margin-left: 1%;
  margin-right: 1%;
  overflow: auto;
}

.modal-media img{
  max-height: 100%;
  max-width: 100%;
  object-fit: contain;
  margin-bottom: 1%;
  margin-left: auto;
  margin-right: auto;
}

.modal-text {
  margin-top: 2%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 10%;
  text-align: left;
}

hr {
  border: 0;
  height: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.modal-text h1{
  margin-bottom: 0.25em;
}

.modal-text h2{
  margin: 0.2em 0;
  font-size: 24px;
}

.modal-text p{
  margin-bottom: 1em;
}

.modal-lists {
  margin-bottom: 1em;
  font-size: 20px;
}

.contact-section {
  min-height: 25vh;
  margin: auto;
  display: flex;
  background: #091E42;
  position: relative;
  z-index: 99;
}

.contact-section-container {
  width:90%;
  margin: auto;
}

.contact-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
}

.contact-container h1{
  color: #FFFFFF;
}

.contact-text {
  color: #FFFFFF;
  font-size: 30px;
  font-weight: bold;
}

footer {
  background-color: darkgray;
  text-align: center;
  z-index: 999;
  position: relative;
}

footer p{
  font-size: 14px;
}